<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template functional>
    <footer class="the-footer flex-wrap justify-between" :class="classes">
        <span>Suivez-nous sur les réseaux sociaux  
            <a href="https://www.facebook.com/aabvieofficielle/" target="_blank">
                <feather-icon style="padding-left: 4px;padding-right: 3px;" svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="FacebookIcon"></feather-icon>
            </a>
            <a href="https://www.linkedin.com/in/atlantique-assurances-benin-vie-2507891a2" target="_blank">
                <feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="LinkedinIcon"></feather-icon>
            </a>©️ {{ new Date().getFullYear() }} Atlantique Assurances Bénin Vie - Tous droits réservés.
        <router-link :to="{name: 'PrivacyAndSecurity'}" target="_blank">
            Politiques juridiques, confidentialité et de sécurité
        </router-link>
        <a href="https://bilansanteinternational.com/e-bsi/" target="_blank"> - Powered by E-BSI</a>
        </span>
        <span class="md:flex hidden items-center">
            <!--<span>Hand-crafted & Made with</span>
            <feather-icon icon="HeartIcon" svgClasses="stroke-current text-danger w-6 h-6" class="ml-2" />-->
        </span>
    </footer>
</template>

<script>
export default {
    name: "the-footer",
    props: {
        classes: {
            type: String,
        },
    }
}
</script>
